import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'

import 'flatpickr/dist/flatpickr.css'

export default class extends Controller {
  connect () {
    this.initializeFlatpickr()
  }

  initializeFlatpickr () {
    this.flatpickr = flatpickr(this.element, {
      altInput: true,
      altFormat: 'm/d/Y',
      dateFormat: 'Y-m-d',
      defaultDate: this.element.value,
      minDate: this.element.min,
      maxDate: this.element.max,
      allowInput: this.element.required
    })

    if (this.element.required) this.disableManualInput()
  }

  disableManualInput () {
    this.flatpickr._input.onkeypress = () => false
    this.flatpickr._input.onpaste = () => false
    this.flatpickr._input.style.caretColor = 'transparent'
    this.flatpickr._input.style.cursor = 'pointer'
  }
}

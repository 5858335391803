import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['itemList', 'selectedItemsTemplate', 'dialog', 'modalItemList', 'filter', 'addMoreSection', 'statusBad', 'statusGood']

  connect () {
    this.reportedItems = new Set()
    this.originalItems = this.currentFilterListItems
    this.#updateAddMoreSectionVisibility()
  }

  handleStatusChange () {
    if (this.statusGoodTarget.checked) {
      this.#clearReportedItems()
    }

    if (this.statusBadTarget.checked) {
      this.openModal()
    }
  }

  openModal () {
    this.dialogTarget.showModal()
    this.#resetSearch()
    this.#toggleEmptyFilterListState()
  }

  closeModal () {
    this.dialogTarget.close()
    this.#dispatchItemChange()
  }

  selectItem ({ target }) {
    const { description, severity } = target.dataset

    if (this.reportedItems.has(description)) return

    this.#addHiddenInputs(description, severity)
    this.#addItemToReportedList(description)
    this.#hideReportedItemFromFilterList(target)
    this.#updateAddMoreSectionVisibility()
    this.closeModal()
  }

  removeItem ({ target }) {
    const itemEntry = target.closest('.item-entry')
    const itemId = itemEntry.dataset.description

    this.#removeHiddenInputs(itemId)
    itemEntry.remove()
    this.reportedItems.delete(itemId)
    this.#unhideItemFromFilterList(itemId)
    this.#updateAddMoreSectionVisibility()
    this.#toogleToGoodValueIfNoItems()
    this.#dispatchItemChange()
  }

  filterItems ({ target }) {
    const filterValue = target.value.toLowerCase()
    const itemsToShow = this.#getFilteredItems(filterValue)
    this.#updateModalFilterList(itemsToShow)
  }

  validateToGoodState () {
    this.#toogleToGoodValueIfNoItems()
  }

  // Private

  #resetSearch () {
    this.filterTarget.value = ''
    this.filterTarget.dispatchEvent(new Event('input', { bubbles: true }))
  }

  #addHiddenInputs (description, severity) {
    const hiddenInputs = document.createElement('div')
    hiddenInputs.innerHTML = `
      <input type="hidden" name="categories[${this.element.dataset.categoryName}][items][][title]" value="${description}">
      <input type="hidden" name="categories[${this.element.dataset.categoryName}][items][][severity]" value="${severity}">
    `
    this.itemListTarget.appendChild(hiddenInputs)
  }

  #addItemToReportedList (description) {
    const content = this.selectedItemsTemplateTarget.innerHTML.replace(/TEMPLATE_DESCRIPTION/g, description)
    this.itemListTarget.insertAdjacentHTML('beforeend', content)

    this.reportedItems.add(description)
  }

  #hideReportedItemFromFilterList (item) {
    item.closest('li').classList.add('hidden')
  }

  #removeHiddenInputs (itemId) {
    this.itemListTarget.querySelectorAll(`input[value='${itemId}']`).forEach(input => {
      input.parentNode.remove()
    })
  }

  #unhideItemFromFilterList (itemId) {
    this.originalItems
      .find(item => item.querySelector('button')?.dataset.description === itemId)
      ?.classList.remove('hidden')
  }

  #toggleEmptyFilterListState () {
    if (this.reportedItems.size === this.originalItems.length) {
      this.emptyStateVisibility = true
    } else {
      this.emptyStateVisibility = false
    }
  }

  #getFilteredItems (filterValue) {
    return this.originalItems.filter(item => {
      const button = item.querySelector('button')
      const itemReference = button.firstElementChild.textContent.toLowerCase()
      const itemText = button.lastElementChild.textContent.toLowerCase()
      return itemText.includes(filterValue) || itemReference.includes(filterValue)
    })
  }

  #updateModalFilterList (itemsToShow) {
    const itemsToRemove = this.currentFilterListItems.filter(item => !itemsToShow.includes(item))
    const itemsToAdd = itemsToShow.filter(item => !this.currentFilterListItems.includes(item))

    itemsToRemove.forEach(item => this.modalItemListTarget.removeChild(item))
    itemsToAdd.forEach(item => this.modalItemListTarget.appendChild(item))
  }

  #updateAddMoreSectionVisibility () {
    const hasReportedItems = this.itemListTarget.children.length > 0

    this.addMoreSectionTarget.classList.toggle('hidden', !hasReportedItems)
    this.addMoreSectionTarget.parentNode.querySelector('h3').classList.toggle('hidden', !hasReportedItems)
  }

  #dispatchItemChange () {
    this.dispatch('item-change')
  }

  #clearReportedItems () {
    this.itemListTarget.innerHTML = ''
    this.reportedItems.clear()

    this.originalItems.forEach(item => item.classList.remove('hidden'))
    this.#updateAddMoreSectionVisibility()
  }

  #toogleToGoodValueIfNoItems () {
    if (this.reportedItems.size === 0) {
      this.statusGoodTarget.checked = true
      this.#dispatchItemChange()
    }
  }

  set emptyStateVisibility (visible) {
    this.modalItemListTarget.querySelector('li.emptyCategoryItems').classList.toggle('hidden', !visible)
  }

  get emptyStateVisibility () {
    return !this.modalItemListTarget.querySelector('li.emptyCategoryItems').classList.contains('hidden')
  }

  get currentFilterListItems () {
    return Array.from(this.modalItemListTarget.querySelectorAll('li.item'))
  }
}

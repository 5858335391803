import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.validate()
  }

  validate () {
    const isValid = this.#formIsValid() && !this.#reportedCategoryWithoutItems()
    this.submitButton.disabled = !isValid
  }

  #formIsValid () {
    return this.element.checkValidity()
  }

  #reportedCategoryWithoutItems () {
    return Array.from(this.categoryItems).some(categoryItem => {
      const reportOption = categoryItem.querySelector('input[value="bad"]')
      const itemList = categoryItem.querySelector('div[data-category-items-target="itemList"]')
      return reportOption.checked && itemList.children.length === 0
    })
  }

  get submitButton () {
    return this.element.querySelector('button[type="submit"]')
  }

  get categoryItems () {
    return this.element.querySelectorAll('div[data-controller="category-items"]')
  }
}
